<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import Swal from "sweetalert2";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Manage Roles Component
 */
export default {
  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  data() {
    return {

      permissionTypes: null,
      roles: null,
      rolePermissions: null,

      visibleModal: false,
      form: {
        role: {},

        permissions: []
      }

    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('manage-roles.title'),
          active: true
        }
      ]
    },

    async loadPermissionTypes() {
      try {
        const result = await axios.get(`/role/permission/types`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.permissionTypes = result.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadRoles() {
      try {
        const result = await axios.get(`/role`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.roles = result.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadRolePermissions() {
      try {
        const result = await axios.get(`/role/permission`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.rolePermissions = result.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    checkPermission(roleId, permissionType) {
      if (!this.rolePermissions) {
        return null
      }

      return this.rolePermissions.find(item => item.roleId === roleId && item.permissionType === permissionType);
    },

    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.role = {}
      this.form.permissions = []
    },

    editRole(role) {
      this.form.role = Object.assign({}, role)
      for (const rolePermission of this.rolePermissions) {
        if (rolePermission.roleId === role.id) {
          this.form.permissions.push(rolePermission.permissionType)
        }
      }

      this.openModal()
    },

    createOrEditRole() {
      const json = JSON.stringify({
        "role": this.form.role,
        "permissions": this.form.permissions
      })

      axios.post(`/role`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        Swal.fire("Sukces!", this.form.role.id ? "Edytowano rolę" : "Utworzono nową rolę", "success").then(() => {
          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    }

  },

  async created() {
    await this.loadPermissionTypes()
    await this.loadRoles()
    await this.loadRolePermissions()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('manage-roles.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success" @click="openModal">
                  <i class="mdi mdi-plus mr-2"></i> Dodaj nową rolę
                </a>
              </div>
            </div>

            <template v-if="permissionTypes">
              <div class="table-responsive py-2">
                <table class="table table-hover table-bordered">
                  <thead>
                  <tr>
                    <th>Nazwa</th>
                    <th v-for="(permissionType, index) in permissionTypes" :key="index">
                      {{ permissionType.replaceAll("_", " ") }}
                    </th>
                    <th>Edycja</th>
                  </tr>
                  </thead>

                  <template v-if="roles">
                    <tr v-for="(role, index) in roles" :key="index">
                      <td>{{ role.name }}</td>
                      <td v-for="(permissionType, index) in permissionTypes" :key="index" class="text-center">
                        <i class="fa" :class="checkPermission(role.id, permissionType) ? 'fa-check' : 'fa-times'"></i>
                      </td>
                      <td>
                        <b-button variant="primary" class="btn-sm" @click="editRole(role)">Edytuj</b-button>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="visibleModal"
        :title="form.role.id ? 'Edytowanie roli' : 'Tworzenie nowej roli'"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label>Nazwa roli</label>
          <input v-model="form.role.name" type="text" class="form-control"/>
        </div>

        <div class="form-group" v-if="permissionTypes">
          <div class="custom-control custom-checkbox" v-for="(permissionType, index) in permissionTypes" :key="index">
            <input :value="permissionType" v-model="form.permissions" type="checkbox" class="custom-control-input"
                   :id="`permission-${permissionType}`"/>
            <label class="custom-control-label"
                   :for="`permission-${permissionType}`">{{ permissionType.replaceAll("_", " ") }}</label>
          </div>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditRole" variant="success">{{
              $t(form.role.id ? 'Edytuj rolę' : 'Utwórz rolę')
            }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>